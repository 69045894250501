import profilePic from './media/images/profilepic.jpg';
import './App.css';

function App() {
  return (
    <div class="app">
      <header class="header">
        <img src={profilePic} class="profilePic" alt="logo" />
        <h1 class="name">
          <span class="initial">J</span>ordi <span class="initial">B</span>een
        </h1>
        <ul class="traits code">
          <li><a href="https://github.com/jordibeen" target="_blank" rel="noreferrer">full-time back-end engineer 👨🏼‍💻 </a></li>
          <li><a href="https://www.youtube.com/c/jordibeen" target="_blank" rel="noreferrer">part-time guitarist 🎸</a></li>
          <li>free-time chess enthusiast ♟</li>
        </ul>
        <p class="mail-to">email me at <a class="mail-link" href="mailto:jordi@been.gg?subject=Hello!&body=Let's be friends">jordi@been.gg</a></p>
      </header>
    </div>
  );
}

export default App;
